import React from "react";
import NavBar from "../features/navbar/Navbar";
import AboutUs from "./AboutUs";

const AboutPage = () => {
  return (
    <>
      <NavBar>
        <AboutUs />
      </NavBar>
    </>
  );
};

export default AboutPage;
