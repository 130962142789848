import React from "react";
import NavBar from "../navbar/Navbar";

const PrivacyPoly = () => {
  return (
    <NavBar>
      <div className="container mx-auto py-8 px-4 lg:px-0">
        <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>
        <p className="mb-4 text-center">Last updated: june 14, 2024</p>
        <p className="mb-4">
          This Privacy Policy describes our policies and procedures on the
          collection, use, and disclosure of your information when you use our
          service and tells you about your privacy rights and how the law
          protects you.
        </p>
        <p className="mb-4">
          We use your personal data to provide and improve the service. By using
          the service, you agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-2">
          Interpretation and Definitions
        </h2>
        <h3 className="text-xl font-semibold mt-4 mb-2">Interpretation</h3>
        <p className="mb-4">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h3 className="text-xl font-semibold mt-4 mb-2">Definitions</h3>
        <p className="mb-4">For the purposes of this Privacy Policy:</p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            <strong>Account</strong> means a unique account created for you to
            access our service or parts of our service.
          </li>
          <li className="mb-2">
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by, or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest, or other securities entitled to vote for election of
            directors or other managing authority.
          </li>
          <li className="mb-2">
            <strong>Company</strong> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Asha Bhel LLC, Asha Bhel
            - Daund Rd, Maniknagar, Ahmednagar, Maharashtra 414001.
          </li>
          <li className="mb-2">
            <strong>Cookies</strong> are small files that are placed on your
            computer, mobile device, or any other device by a website,
            containing the details of your browsing history on that website
            among its many uses.
          </li>
          <li className="mb-2">
            <strong>Country</strong> refers to: Maharashtra, India.
          </li>
          <li className="mb-2">
            <strong>Device</strong> means any device that can access the service
            such as a computer, a cellphone, or a digital tablet.
          </li>
          <li className="mb-2">
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </li>
          <li className="mb-2">
            <strong>Service</strong> refers to the Website.
          </li>
          <li className="mb-2">
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the service, to provide the service on behalf of the
            Company, to perform services related to the service, or to assist
            the Company in analyzing how the service is used.
          </li>
          <li className="mb-2">
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the service or from the service
            infrastructure itself (for example, the duration of a page visit).
          </li>
          <li className="mb-2">
            <strong>Website</strong> refers to Asha Bhel, accessible from{" "}
            <a href="https://ashabhel.in/" className="text-blue-600 underline">
              https://ashabhel.in
            </a>
          </li>
          <li className="mb-2">
            <strong>You</strong> means the individual accessing or using the
            service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the service, as applicable.
          </li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-2">
          Collecting and Using Your Personal Data
        </h2>
        <h3 className="text-xl font-semibold mt-4 mb-2">
          Types of Data Collected
        </h3>
        <h4 className="text-lg font-semibold mt-4 mb-2">Personal Data</h4>
        <p className="mb-4">
          While using our service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">Email address</li>
          <li className="mb-2">First name and last name</li>
          <li className="mb-2">Phone number</li>
          <li className="mb-2">
            Address, State, Province, ZIP/Postal code, City
          </li>
          <li className="mb-2">Usage Data</li>
        </ul>
        <h4 className="text-lg font-semibold mt-4 mb-2">Usage Data</h4>
        <p className="mb-4">
          Usage Data is collected automatically when using the service.
        </p>
        <p className="mb-4">
          Usage Data may include information such as your device's Internet
          Protocol address (e.g., IP address), browser type, browser version,
          the pages of our service that you visit, the time and date of your
          visit, the time spent on those pages, unique device identifiers, and
          other diagnostic data.
        </p>
        <p className="mb-4">
          When you access the service by or through a mobile device, we may
          collect certain information automatically, including, but not limited
          to, the type of mobile device you use, your mobile device unique ID,
          the IP address of your mobile device, your mobile operating system,
          the type of mobile Internet browser you use, unique device
          identifiers, and other diagnostic data.
        </p>
        <p className="mb-4">
          We may also collect information that your browser sends whenever you
          visit our service or when you access the service by or through a
          mobile device.
        </p>
        <h4 className="text-lg font-semibold mt-4 mb-2">
          Tracking Technologies and Cookies
        </h4>
        <p className="mb-4">
          We use cookies and similar tracking technologies to track the activity
          on our service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze our service. The technologies we use may
          include:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            <strong>Cookies or Browser Cookies.</strong> A cookie is a small
            file placed on your device. You can instruct your browser to refuse
            all cookies or to indicate when a cookie is being sent. However, if
            you do not accept cookies, you may not be able to use some parts of
            our service. Unless you have adjusted your browser setting so that
            it will refuse cookies, our service may use cookies.
          </li>
          <li className="mb-2">
            <strong>Web Beacons.</strong> Certain sections of our service and
            our emails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul>
        <p className="mb-4">
          Cookies can be "Persistent" or "Session" Cookies. Persistent cookies
          remain on your personal computer or mobile device when you go offline,
          while session cookies are deleted as soon as you close your web
          browser. You can learn more about cookies on{" "}
          <a
            href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
            className="text-blue-600 underline"
          >
            TermsFeed website
          </a>{" "}
          article.
        </p>
        <p className="mb-4">
          We use both session and persistent cookies for the purposes set out
          below:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            <strong>Necessary / Essential Cookies</strong>
            <p className="pl-5 mb-2">
              Type: Session Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These cookies are essential to provide you with services
              available through the website and to enable you to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these cookies, the services that you
              have asked for cannot be provided, and we only use these cookies
              to provide you with those services.
            </p>
          </li>
          <li className="mb-2">
            <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            <p className="pl-5 mb-2">
              Type: Persistent Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These cookies identify if users have accepted the use of
              cookies on the website.
            </p>
          </li>
          <li className="mb-2">
            <strong>Functionality Cookies</strong>
            <p className="pl-5 mb-2">
              Type: Persistent Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These cookies allow us to remember choices you make when
              you use the website, such as remembering your login details or
              language preference. The purpose of these cookies is to provide
              you with a more personal experience and to avoid you having to
              re-enter your preferences every time you use the website.
            </p>
          </li>
        </ul>
        <h3 className="text-xl font-semibold mt-4 mb-2">
          Use of Your Personal Data
        </h3>
        <p className="mb-4">
          The Company may use Personal Data for the following purposes:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            <strong>To provide and maintain our service</strong>, including to
            monitor the usage of our service.
          </li>
          <li className="mb-2">
            <strong>To manage Your Account:</strong> to manage your registration
            as a user of the service. The personal data you provide can give you
            access to different functionalities of the service that are
            available to you as a registered user.
          </li>
          <li className="mb-2">
            <strong>For the performance of a contract:</strong> the development,
            compliance, and undertaking of the purchase contract for the
            products, items, or services you have purchased or of any other
            contract with us through the service.
          </li>
          <li className="mb-2">
            <strong>To contact you:</strong> To contact you by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products, or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </li>
          <li className="mb-2">
            <strong>To provide you with news</strong>, special offers, and
            generaljsx Copy code information about other goods, services, and
            events which we offer that are similar to those that you have
            already purchased or enquired about unless you have opted not to
            receive such information.
          </li>
          <li className="mb-2">
            <strong>To manage your requests:</strong> To attend and manage your
            requests to us.
          </li>
          <li className="mb-2">
            <strong>For business transfers:</strong> We may use your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which personal
            data held by us about our service users is among the assets
            transferred.
          </li>
          <li className="mb-2">
            <strong>For other purposes:</strong> We may use your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns, and to
            evaluate and improve our service, products, services, marketing, and
            your experience.
          </li>
        </ul>
        <p className="mb-4">
          We may share your personal information in the following situations:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            <strong>With Service Providers:</strong> We may share your personal
            information with service providers to monitor and analyze the use of
            our service, to contact you.
          </li>
          <li className="mb-2">
            <strong>For business transfers:</strong> We may share or transfer
            your personal information in connection with, or during negotiations
            of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.
          </li>
          <li className="mb-2">
            <strong>With Affiliates:</strong> We may share your information with
            our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include our parent company and
            any other subsidiaries, joint venture partners, or other companies
            that we control or that are under common control with us.
          </li>
          <li className="mb-2">
            <strong>With business partners:</strong> We may share your
            information with our business partners to offer you certain
            products, services, or promotions.
          </li>
          <li className="mb-2">
            <strong>With other users:</strong> When you share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside.
          </li>
          <li className="mb-2">
            <strong>With your consent:</strong> We may disclose your personal
            information for any other purpose with your consent.
          </li>
        </ul>
        jsx Copy code
        <h3 class="text-xl font-semibold mb-4">
          Retention of Your Personal Data
        </h3>
        <p class="mb-4">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p class="mb-4">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <h3 class="text-xl font-semibold mb-4">
          Transfer of Your Personal Data
        </h3>
        <p class="mb-4">
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p class="mb-4">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p class="mb-4">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <h3 class="text-xl font-semibold mb-4">Delete Your Personal Data</h3>
        <p class="mb-4">
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </p>
        <p class="mb-4">
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </p>
        <p class="mb-4">
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </p>
        <p class="mb-4">
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
        <h3 class="text-xl font-semibold mb-4">
          Disclosure of Your Personal Data
        </h3>
        <h4 class="text-lg font-semibold mb-2">Business Transactions</h4>
        <p class="mb-4">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <h4 class="text-lg font-semibold mb-2">Law enforcement</h4>
        <p class="mb-4">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h4 class="text-lg font-semibold mb-2">Other legal requirements</h4>
        <p class="mb-4">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <h3 class="text-xl font-semibold mb-4">
          Security of Your Personal Data
        </h3>
        <p class="mb-4">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <h2 class="text-2xl font-bold mb-4">Children's Privacy</h2>
        <p class="mb-4">
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </p>
        <p class="mb-4">
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </p>
        <h2 class="text-2xl font-bold mb-4">Links to Other Websites</h2>
        <p class="mb-4">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p class="mb-4">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h2 class="text-2xl font-bold mb-4">Changes to this Privacy Policy</h2>
        <p class="mb-4">
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p class="mb-4">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p class="mb-4">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h2 class="text-2xl font-bold mb-4">Contact Us</h2>
        <p class="mb-4">
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>
            <p>
              By visiting this page on our website:{" "}
              <a
                href="https://ashabhel.in"
                rel="external nofollow noopener"
                class="text-blue-600 underline"
              >
                https://ashabhel.in
              </a>
            </p>
          </li>
          <li>
            <p>By phone number: 8600222200</p>
          </li>
        </ul>
      </div>
    </NavBar>
  );
};

export default PrivacyPoly;
