import { useMotionValue, motion, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { FiArrowRight } from "react-icons/fi";
import NavBar from "../features/navbar/Navbar";

const Menu = [
  {
    heading: "Veg Pulao",
    subheading: "Aromatic rice with flavorful veggies",
    imgSrc: "https://i.postimg.cc/mkp2y0nt/Veg-pulao.jpg",
    href: "#",
  },
  {
    heading: "Cheese mayonnaise Bhel",
    subheading: "Order Now",
    imgSrc:
      "https://i.postimg.cc/d1nGHKRs/Asha-special-cheese-mayonnaise-Bhel.jpg",
    href: "#",
  },
  {
    heading: "Cheese Kachori Bhel Oli",
    subheading: "A fusion of flavors and textures",
    imgSrc: "https://i.postimg.cc/CL7zwnYx/Cheese-Kachori-Bhel-Oli.jpg",
    href: "#",
  },
  {
    heading: "Cheese Bhel Oli",
    subheading: "Savor the cheesy crunch delight",
    imgSrc: "https://i.postimg.cc/QxJjc2n4/Cheese-Bhel-Oli.jpg",
    href: "#",
  },
  {
    heading: "Dahi Bhel",
    subheading: "Cool yogurt meets spicy bhel",
    imgSrc: "https://i.postimg.cc/KY1Yx9vZ/Dahi-Bhel.jpg",
    href: "#",
  },
  {
    heading: "Dahi Bhel Oli",
    subheading: "Yogurt-infused tangy bhel mix",
    imgSrc: "https://i.postimg.cc/CKscM4vQ/Dahi-Bhel-Oli.jpg",
    href: "#",
  },
  {
    heading: "Jain Bhel Oli",
    subheading: "Pure delight for Jain cuisine lovers",
    imgSrc: "https://i.postimg.cc/vm86YjND/Jain-Bhel-Oli.jpg",
    href: "#",
  },
  {
    heading: "Kachori Bhel",
    subheading: "A fusion of kachori and bhel",
    imgSrc: "https://i.postimg.cc/PqcZSySx/Kachori-Bhel.jpg",
    href: "#",
  },
  {
    heading: "Dahi Kachori Bhel Oli",
    subheading: "A heavenly blend of yogurt and bhel",
    imgSrc: "https://i.postimg.cc/KcwChhHc/Dahi-Kachori-Bhel-Oli.jpg",
    href: "#",
  },
  {
    heading: "Dahi Kachori Bhel",
    subheading: "A tantalizing twist to classic bhel",
    imgSrc: "https://i.postimg.cc/vmKtxb9c/Dahi-Kachori-Bhel.jpg",
    href: "#",
  },
  {
    heading: "Asha special chiwda",
    subheading: "Order Now",
    imgSrc: "https://i.postimg.cc/wjNMLRHH/Asha-special-chiwda.jpg",
    href: "#",
  },
  {
    heading: "Asha special SPDP",
    subheading: "Our Most Special SPDP",
    imgSrc: "https://i.postimg.cc/1tcTVQvs/Asha-special-SPDP.jpg",
    href: "#",
  },
  {
    heading: "Bhaji Shev",
    subheading: "Try our Shev Bhaji or Masala Shev",
    imgSrc: "https://i.postimg.cc/xChwSRpb/Bhaji-shev-masala-shev.jpg",
    href: "#",
  },

  {
    heading: "Cheese Kachori",
    subheading: "A cheesy twist to traditional kachori",
    imgSrc: "https://i.postimg.cc/FRSV6gMR/Chesse-Kachori-1.jpg",
    href: "#",
  },
  {
    heading: "Cornflakes Chiwda",
    subheading: "Crunchy munchy cornflakes mix",
    imgSrc: "https://i.postimg.cc/3N45V6KG/Cornflakes-Chiwda.jpg",
    href: "#",
  },
  {
    heading: "Cut Dosa",
    subheading: "Dosa slices for a crispy treat",
    imgSrc: "https://i.postimg.cc/660Mxz72/Cut-Dosa-1.jpg",
    href: "#",
  },

  {
    heading: "Dahi Kachori",
    subheading: "Yogurt-filled savory delight",
    imgSrc: "https://i.postimg.cc/fWCQGPhJ/Dahi-Kachori-1.jpg",
    href: "#",
  },

  {
    heading: "Finger chips",
    subheading: "Crispy potato finger delights",
    imgSrc: "https://i.postimg.cc/qM7qSnWH/Finger-chips.jpg",
    href: "#",
  },
  {
    heading: "Dahi Puri",
    subheading: "Yogurt-filled crispy puris",
    imgSrc: "https://i.postimg.cc/cHJP0xWC/Dahi-Puri-1.jpg",
    href: "#",
  },
  {
    heading: "Handi Kachori",
    subheading: "Traditional kachori in a pot",
    imgSrc: "https://i.postimg.cc/k5Lh3xT1/Handi-Kachori.jpg",
    href: "#",
  },
  {
    heading: "Idly sambar",
    subheading: "Soft idlis with flavorful sambar",
    imgSrc: "https://i.postimg.cc/6qbvgh0T/Idly-sambar.jpg",
    href: "#",
  },

  {
    heading: "Jain Misal",
    subheading: "Spicy treat for Jain dietary needs",
    imgSrc: "https://i.postimg.cc/fTBs4rm8/Jain-Misal.jpg",
    href: "#",
  },

  {
    heading: "Kala Sona",
    subheading: "Rich and savory black beauty",
    imgSrc: "https://i.postimg.cc/Y0TskQ2q/Kala-Sona.jpg",
    href: "#",
  },
  {
    heading: "Masala Dosa",
    subheading: "Spiced dosa for a flavorful bite",
    imgSrc: "https://i.postimg.cc/fR8gR4Rr/Masala-Dosa.jpg",
    href: "#",
  },
  {
    heading: "Masala paav",
    subheading: "Buttery pav with a spicy twist",
    imgSrc: "https://i.postimg.cc/8PJCHGqh/Masala-paav.jpg",
    href: "#",
  },
  {
    heading: "Masala Puri",
    subheading: "Crunchy puris with a spicy kick",
    imgSrc: "https://i.postimg.cc/XqX9Lhbp/Masala-Puri.jpg",
    href: "#",
  },
  {
    heading: "Mataki Bhel Suki",
    subheading: "A savory snack with a spicy kick",
    imgSrc: "https://i.postimg.cc/8PmrWNWt/Mataki-Bhel-Suki.jpg",
    href: "#",
  },
  {
    heading: "Mendu wada sambar",
    subheading: "Spongy wadas with flavorful sambar",
    imgSrc: "https://i.postimg.cc/WbzhDgNb/Mendu-wada-sambar.jpg",
    href: "#",
  },
  {
    heading: "Misal",
    subheading: "A spicy mix of sprouts and spices",
    imgSrc: "https://i.postimg.cc/pVFjW3ZX/Misal.jpg",
    href: "#",
  },
  {
    heading: "Misal Farsan",
    subheading: "Crunchy farsan topped with spicy misal",
    imgSrc: "https://i.postimg.cc/9F0vwtDM/Misal-Farsan.jpg",
    href: "#",
  },
  {
    heading: "Oly Bhel",
    subheading: "Spicy crunch in every bite!",
    imgSrc: "https://i.postimg.cc/VNmdfdGm/Oly-Bhel.jpg",
    href: "#",
  },
  {
    heading: "Onion Uttapam",
    subheading: "Savoury South Indian delight",
    imgSrc: "https://i.postimg.cc/Qdtj01yB/Onion-Uttapam.jpg",
    href: "#",
  },
  {
    heading: "Panipuri",
    subheading: "Tiny explosions of flavor",
    imgSrc: "https://i.postimg.cc/HkRVqdR9/Panipuri.jpg",
    href: "#",
  },
  {
    heading: "Paper Dosa",
    subheading: "Crispy, thin and irresistible",
    imgSrc: "https://i.postimg.cc/xdPqcMqt/Paper-Dosa.jpg",
    href: "#",
  },
  {
    heading: "Plain Kachori",
    subheading: "Classic street snack delight",
    imgSrc: "https://i.postimg.cc/mg9WX4s9/Plain-kachori.jpg",
    href: "#",
  },
  {
    heading: "Ragda Kachori",
    subheading: "Satisfying, spicy goodness",
    imgSrc: "https://i.postimg.cc/NG83rDx6/Ragda-kachori.jpg",
    href: "#",
  },
  {
    heading: "Salted Bundi",
    subheading: "Crunchy bites of savory joy",
    imgSrc: "https://i.postimg.cc/qRJ5cVw2/Salted-Bundi.jpg",
    href: "#",
  },
  {
    heading: "Shabudana Khichadi",
    subheading: "A comforting, flavorful dish",
    imgSrc: "https://i.postimg.cc/rmgx47Z0/Shabudana-Khichadi.jpg",
    href: "#",
  },
  {
    heading: "Shabudana Wada",
    subheading: "Crispy spheres of deliciousness",
    imgSrc: "https://i.postimg.cc/BbBStGmk/Shabudana-Wada.jpg",
    href: "#",
  },
  {
    heading: "Shev Kachori",
    subheading: "Savory crunch with spicy filling",
    imgSrc: "https://i.postimg.cc/tR28RTky/Shev-kachori.jpg",
    href: "#",
  },
  {
    heading: "Shev Puri",
    subheading: "The perfect balance of tangy and spicy",
    imgSrc: "https://i.postimg.cc/FKYR1ndp/Shev-Puri.jpg",
    href: "#",
  },
  {
    heading: "Amul PaavBhaji",
    subheading: "Buttery goodness with a kick",
    imgSrc: "https://i.postimg.cc/qMGXGgmY/Special-Amul-Paavbhaji.jpg",
    href: "#",
  },
  {
    heading: "Cheese PaavBHaji",
    subheading: "Indulgent and gooey delight",
    imgSrc: "https://i.postimg.cc/6Q69Gb8g/Special-Cheese-Paavbhaji.jpg",
    href: "#",
  },
  {
    heading: "Special Dahi Kachori",
    subheading: "Creamy, tangy, and spicy burst",
    imgSrc: "https://i.postimg.cc/T1NbZ5ZY/Special-Dahi-Kachori.jpg",
    href: "#",
  },
  {
    heading: "Sponge Dosa",
    subheading: "Soft, fluffy and utterly delicious",
    imgSrc: "https://i.postimg.cc/HnvSBxzb/Sponge-Dosa.jpg",
    href: "#",
  },
  {
    heading: "Sukhi Bhel",
    subheading: "Crunchy mix of flavorsome goodness",
    imgSrc: "https://i.postimg.cc/pVkXPHy8/Sukhi-Bhel-Parcel.jpg",
    href: "#",
  },
  {
    heading: "Dry Bhel",
    subheading: "Aromatic and crunchy snack",
    imgSrc: "https://i.postimg.cc/v8j9Gf45/Sukhi-Bhel-Dry-Bhel.jpg",
    href: "#",
  },
  {
    heading: "Sukhi Farsan Bhel",
    subheading: "Satisfying crunch with spicy tang",
    imgSrc: "https://i.postimg.cc/rmCkQvDq/Sukhi-Farsan-Bhel.jpg",
    href: "#",
  },
];

export const MenuCard = () => {
  return (
    <>
      <section className="bg-neutral-950 p-4 md:p-8 mt-0">
        <NavBar />
        {Menu.map((menu, index) => (
          <div key={index} className="mx-auto max-w-5xl">
            <Link
              heading={menu.heading}
              subheading={menu.subheading}
              imgSrc={menu.imgSrc}
              href={menu.href}
            />
          </div>
        ))}
      </section>
    </>
  );
};

const Link = ({ heading, imgSrc, subheading, href }) => {
  const ref = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
  const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);

  const handleMouseMove = (e) => {
    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };

  return (
    <motion.a
      href={href}
      ref={ref}
      onMouseMove={handleMouseMove}
      initial="initial"
      whileHover="whileHover"
      className="group relative flex items-center justify-between border-b-2 border-neutral-700 py-4 transition-colors duration-500 hover:border-neutral-50 md:py-8"
    >
      <div>
        <motion.span
          variants={{
            initial: { x: 0 },
            whileHover: { x: -16 },
          }}
          transition={{
            type: "spring",
            staggerChildren: 0.075,
            delayChildren: 0.25,
          }}
          className="relative z-10 block text-4xl font-bold text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50 md:text-6xl"
        >
          {heading.split("").map((l, i) => (
            <motion.span
              variants={{
                initial: { x: 0 },
                whileHover: { x: 16 },
              }}
              transition={{ type: "spring" }}
              className="inline-block"
              key={i}
            >
              {l}
            </motion.span>
          ))}
        </motion.span>
        <span className="relative z-10 mt-2 block text-base text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50">
          {subheading}
        </span>
      </div>

      <motion.img
        style={{
          top,
          left,
          translateX: "-50%",
          translateY: "-50%",
        }}
        variants={{
          initial: { scale: 0, rotate: "-12.5deg" },
          whileHover: { scale: 1, rotate: "12.5deg" },
        }}
        transition={{ type: "spring" }}
        src={imgSrc}
        className="absolute z-0 h-24 w-32 rounded-lg object-cover md:h-48 md:w-64"
        alt={`Image representing a link for ${heading}`}
      />

      <motion.div
        variants={{
          initial: {
            x: "25%",
            opacity: 0,
          },
          whileHover: {
            x: "0%",
            opacity: 1,
          },
        }}
        transition={{ type: "spring" }}
        className="relative z-10 p-4"
      >
        <FiArrowRight className="text-5xl text-neutral-50" />
      </motion.div>
    </motion.a>
  );
};
