import NavBar from "../features/navbar/Navbar";
import ProductList from "../features/product/components/ProductList";
import FooterPage from "../features/common/Footer";
import AboutUs from "./AboutUs";
import HeroSection from "./HeroSection";

function Home() {
  return (
    <div>
      <NavBar>
        <div className="">
          <HeroSection></HeroSection>
        </div>
        <ProductList></ProductList>
        <div className="w-full border h-12 border-e-red-200 bg-gray-800">
          <h1 className="text-white text-xl text-center font-bold m-2">
            ABOUT US
          </h1>
        </div>
        <AboutUs />
      </NavBar>
      <FooterPage></FooterPage>
    </div>
  );
}

export default Home;
