import React from "react";
import Lottie from "lottie-react";
import Aboutus from "../features/Assets/Aboutus.json";

const AboutUs = () => {
  return (
    <div className="py-16 bg-white">
      <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
          <div className="md:5/12 lg:w-5/12">
            <img src="./ecommerce.png" alt="E-commerce" />
          </div>
          <div className="md:7/12 lg:w-6/12">
            <h2 className="text-2xl text-gray-900 font-bold md:text-4xl">
              Asha Bhel: Good Memories Since 1963
            </h2>
            <p className="mt-6 text-gray-600">
              Since 1963, Asha Bhel of VISHAL RAMESHLAL BANBERU has been your go-to for mouthwatering
              delights. Our star, Bhel, embodies our passion for great taste.
              We're not just a delivery service; we're memory makers, infusing
              each order with nostalgia and top-notch flavors.
            </p>
            <p className="mt-4 text-gray-600">
              At Asha Bhel, we get that a meal is more than just food - it's
              comfort, joy, and a moment to cherish. Alongside our iconic Bhel,
              our menu covers breakfast, lunch, and dinner. Every dish, made
              with love and quality ingredients, promises a flavorful journey
              delivered to your door.
            </p>
          </div>
        </div>
        <div className="mt-12 text-center">
          <h3 className="text-xl text-gray-900 font-semibold md:text-2xl">
            Visit Us At:
          </h3>
          <p className="mt-4 text-gray-600 text-lg font-medium animate-pulse">
            PLOT NO. 189, SWASTIK CHOWK, PUNE BUSTAND SAMOR, AHMEDNAGAR,
            Ahmednagar Taluka, Ahmednagar, Maharashtra -414001
          </p>
          <h3 className="text-xl text-gray-900 font-semibold mt-8 md:text-2xl">
            Contact Us:
          </h3>
            <p className="mt-4 text-gray-600 text-lg font-medium animate-bounce">
            VISHAL RAMESHLAL BANBERU
          </p>
          <p className="mt-4 text-gray-600 text-lg font-medium animate-bounce">
            +91 86002 22200
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
