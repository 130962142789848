"use client";
import React from "react";
import { Carousel } from "flowbite-react";

const HeroSection = () => {
  return (
    <div className="h-32 sm:h-96 xl:h-96 2xl:h-96 sm:mb-2">
      <Carousel slideInterval={2000}>
        <img src={require("./Asha Bhel 1.jpg")} alt="..." />
        <img src={require("./Asha Bhel 2.jpg")} alt="..." />
        <img src={require("./Asha Bhel 3.jpg")} alt="..." />
        <img src={require("./Asha Bhel 4.jpg")} alt="..." />
        <img src={require("./Asha Bhel 5.jpg")} alt="..." />
        <img src={require("./Asha Bhel 6.jpg")} alt="..." />
        <img src={require("./Asha Bhel 7.jpg")} alt="..." />
      </Carousel>
    </div>
  );
};

export default HeroSection;
