import React from "react";
import Navbar from "../features/navbar/Navbar";
import FooterPage from "../features/common/Footer";

const franchiseData = [
  {
    title: "Asha Bhel Bibwewadi",
    img: "https://i.postimg.cc/MpH0v3Vv/Gangadham-Pune.jpg",
    ZoHref: "http://zoma.to/r/20370983",
    SwiHref: "http://zoma.to/r/20370983",
    description:
      "Asha bhel Bibwewadi Pune Branch Located at Gangavihar, Gangadham Phase 2",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.0497630249415!2d73.87350007523588!3d18.481404982604534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebe820d4fb6f%3A0x85130af74169d2d8!2sAsha%20Bhel!5e0!3m2!1sen!2sin!4v1706247706809!5m2!1sen!2sin",
  },
  {
    title: "Asha Bhel Lohegaon",
    img: "https://i.postimg.cc/MTr7qy3h/Lohegoan.jpg",
    ZoHref: "http://zoma.to/r/20370983",
    SwiHref: "http://zoma.to/r/20370983",
    description:
      "Shop No. 1, Porwal Rd, opp. Paras Basera, Lohegaon, Pune, Maharashtra 411047",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.2406315586845!2d73.90853927523897!3d18.60824348250239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c7006e10a553%3A0x8867535c53db6187!2sAsha%20Bhel!5e0!3m2!1sen!2sin!4v1708673974903!5m2!1sen!2sin",
  },
  {
    title: "Asha Bhel Hadapsar",
    img: "https://lh3.googleusercontent.com/CnANVEhAGURVNF9ak4OiL1oZYhYyDTGR7KVe9bWIREQVjin1FjBzDMLu4Nt7nnzKcmPEo4F7qWz7cScQb-V1gizpB8s0lF3QXS0iJU6RSg=w1200-rw",
    ZoHref: "http://zoma.to/r/20370983",
    SwiHref: "http://zoma.to/r/20370983",
    description:
      "RH 100, Solapur - Pune Hwy, Hadapsar, Pune, Maharashtra 412307",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.693056537703!2d73.95800127523624!3d18.49755758259156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c351208cd863%3A0xdfe2e5f41d1db9f4!2sAsha%20Bhel!5e0!3m2!1sen!2sin!4v1708674505720!5m2!1sen!2sin",
  },
  {
    title: "Asha Bhel Ahmednagar",
    img: "https://lh5.googleusercontent.com/p/AF1QipNrVhWFAGbht2NNCzmcn-YCsH6nCdpJTL6HET9M=w1080-k-no",
    description:
      "lodha heights neta subhash chowk, Ahmednagar, Maharashtra 414001",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.2458214459807!2d74.73466487525091!3d19.09686888211196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdcb1c47c2ba96d%3A0x592067755408237!2sAsha%20Bhel!5e0!3m2!1sen!2sin!4v1708674995517!5m2!1sen!2sin",
  },
  {
    title: "Asha Bhel Maniknagar",
    img: "https://i.postimg.cc/cJvH62LD/Maniknagar.jpg",
    description:
      "Ahmednagar - Daund Rd, Maniknagar, Ahmednagar, Maharashtra 414001",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15082.179723038822!2d74.7140897804041!3d19.08373555582531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdcb1e8f30f7cc5%3A0x61fb2c12bb2617d7!2sAsha%20Bhel!5e0!3m2!1sen!2sin!4v1708675292230!5m2!1sen!2sin",
  },
  {
    title: "Asha Bhel Newasa",
    img: "https://i.postimg.cc/Prqz8TRQ/Rajmudra-Nevasa-phta.jpg",
    description:
      "Nagar Road, Rajmudra Chowk Mukindpur, Newasa Phata, Newasa, Maharashtra 414603",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.5159165836653!2d74.95618267526147!3d19.519451981778605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdc8330d1204cbd%3A0xdeefe9d6ef9abde8!2sAsha%20Bhel%20Newasa!5e0!3m2!1sen!2sin!4v1708675562938!5m2!1sen!2sin",
  },
  {
    title: "Asha Bhel rahuri",
    img: "https://i.postimg.cc/j2qRmBPR/Rahuri-bus-stand-samor.jpg",
    description:
      " Asha bhel,Front of Rahuri Bus Stand,Gokul Nagar, Rahuri, Maharashtra 413705",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.4862075514015!2d74.64594067525825!3d19.391387481879203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdc93844c718aed%3A0xaea6b1e6ab9ada29!2sRahuri%20ST%20Stand!5e0!3m2!1sen!2sin!4v1709383803265!5m2!1sen!2sin",
  },
];

const Franchies = () => {
  return (
    <>
      <Navbar>
        <h1 className="text-center text-orange-600 font-semibold">
          Want to be Part of our Family ?{" "}
          <a
            href="https://drive.google.com/file/d/1TLcX7FPyajn5oJkuLcQ2VP02zz-3DQH9/view?usp=drive_link"
            className="text-green-600"
          >
            Click Here
          </a>
        </h1>
      </Navbar>

      {franchiseData.map((franchise, index) => (
        <div
          key={index}
          className="flex flex-colmb-5 p-4 md:flex-row md:p-8 md:mx-16 shadow-md hover:shadow-lg transition duration-300 ease-in-out hover:shadow-orange-400 mt-2 border border-orange-400 mb-5"
        >
          {/* Left Side - Franchise Information */}
          <div className="w-full md:w-1/2 pr-0 md:pr-8">
            <h2 className="text-2xl font-bold mb-2 md:mb-4 hover:text-red-900 hover:text-2xl transform hover:scale-105 transition duration-300 ease-in-out">
              {franchise.title}
            </h2>
            <p className="text-gray-600">{franchise.description}</p>
            <div className="mt-2">
              <a
                href={franchise.ZoHref}
                className="p-1 bg-red-600 text-black rounded-md text-center font-semibold mx-1 shadow-md shadow-red-900"
              >
                Zomato
              </a>
              <a
                href={franchise.SwiHref}
                className="p-1 bg-orange-600 text-black rounded-md text-center font-semibold mx-1 shadow-md shadow-orange-900"
              >
                Swiggy
              </a>
            </div>
          </div>

          <div className="min-h-60 aspect-h-1 aspect-w-1 w-1/2 mx-2 rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 transition duration-300 ease-in-out group-hover:scale-75 lg:h-60 overflow-hidden">
            <img
              src={franchise.img}
              height={300}
              className="h-full w-full object-cover object-center lg:h-full lg:w-full"
            />
          </div>

          {/* Right Side - Google Map */}
          <div className="w-full md:w-1/2 mt-4 md:mt-0 ml-2">
            <div className="relative overflow-hidden rounded-lg aspect-ratio-16/9">
              <iframe
                src={franchise.mapSrc}
                width="300"
                height="240"
                style={{ border: "2px solid black" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      ))}
      <FooterPage />
    </>
  );
};

export default Franchies;
