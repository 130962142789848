import React from "react";

const RefundCancel = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 sm:p-10">
      <h1 className="text-4xl font-bold mb-4 text-center text-gray-800">
        Refund and Cancellation Policy
      </h1>
      <p className="text-sm text-gray-500 text-center mb-10">
        Last updated: June 1, 2024
      </p>

      <div className="space-y-6">
        <section>
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            1. Introduction
          </h2>
          <p className="text-gray-600">
            Welcome to Asha Bhel. We are committed to providing you with
            excellent service. This refund and cancellation policy outlines the
            conditions under which refunds and cancellations are processed for
            our products and services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            2. Eligibility for Refunds
          </h2>
          <p className="text-gray-600">
            We aim to ensure customer satisfaction with every purchase. If you
            are not completely satisfied with your purchase, you may be eligible
            for a refund under the following conditions:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-600">
            <li>
              The product must be returned in its original condition and
              packaging.
            </li>
            <li>
              The refund request must be made within 14 days of the purchase
              date.
            </li>
            <li>A valid proof of purchase must be provided.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            3. Non-Refundable Items
          </h2>
          <p className="text-gray-600">
            Certain items are non-refundable, including:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-600">
            <li>Perishable goods.</li>
            <li>Gift cards.</li>
            <li>Partially Eaten Food.</li>
            <li>Products on sale or clearance.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            4. How to Request a Refund
          </h2>
          <p className="text-gray-600">
            To request a refund, please contact our customer service team
            through one of the following methods:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-600">
            <li>Call us at: +91 9226222200</li>
            <li>
              Email us at:{" "}
              <a
                href="mailto:ashabhel1993@gmail.com"
                className="text-blue-500 hover:underline"
              >
                ashabhel1993@gmail.com
              </a>
            </li>
          </ul>
          <p className="text-gray-600">
            Provide your order number, proof of purchase, and the reason for the
            refund request. Our team will review your request and respond within
            5 business days.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            5. Cancellation Policy
          </h2>
          <p className="text-gray-600">
            If you need to cancel your order, please do so as soon as possible.
            Orders can be canceled under the following conditions:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-600">
            <li>
              Orders can be canceled within 24 hours of placing the order for a
              full refund.
            </li>
            <li>
              If the order has already been shipped, the cancellation request
              will be treated as a return, and you may be eligible for a refund
              under our return policy.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            6. Processing Refunds
          </h2>
          <p className="text-gray-600">
            Once your return is received and inspected, we will notify you of
            the approval or rejection of your refund. If approved, your refund
            will be processed, and a credit will automatically be applied to
            your original method of payment within 7-10 business days.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            7. Contact Us
          </h2>
          <p className="text-gray-600">
            If you have any questions about our refund and cancellation policy,
            please contact us:
          </p>
          <ul className="list-disc list-inside ml-4 text-gray-600">
            <li>By phone: +91 9226222200</li>
            <li>
              By email:{" "}
              <a
                href="mailto:ashabhel1993@gmail.com"
                className="text-blue-500 hover:underline"
              >
                ashabhel1993@gmail.com
              </a>
            </li>
            <li>
              By visiting our office: Ahmednagar - Daund Rd, Maniknagar,
              Ahmednagar, Maharashtra 414001
            </li>
          </ul>
        </section>

        <p className="text-gray-600">
          Thank you for choosing Asha Bhel. We value your business and look
          forward to serving you again.
        </p>
      </div>
    </div>
  );
};

export default RefundCancel;
