"use client";

import { Footer } from "flowbite-react";
import { Link } from "react-router-dom";
import { BsWhatsapp, BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";

function FooterPage() {
  return (
    <Footer container className="bg-gray-800 rounded-none">
      <div className="w-full m-7 p-3 bg-gray-800">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <Footer.Brand
              className="text-white"
              href="https://www.instagram.com/ashabhel1963/"
              src="https://i.postimg.cc/VLvjvCkp/ecommerce.png"
              alt="Ashabhel Logo"
            />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            <div>
              <Footer.Title className="text-white" title="about" />
              <Footer.LinkGroup col className="text-white">
                <Footer.Link href="#">Asha bhel</Footer.Link>
                <Footer.Link href="https://www.linkedin.com/in/shyamdevp/">
                  Developer
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title className="text-white" title="Follow us" />
              <Footer.LinkGroup col className="text-white">
                <Footer.Link href="https://www.instagram.com/ashabhel1963/">
                  Instagram
                </Footer.Link>
                <Footer.Link href="https://www.facebook.com/AshaBhelOfficial/">
                  Facebook
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title className="text-white" title="Legal" />
              <Footer.LinkGroup col className="text-white">
                <Link to={"/privacy"}>
                  <Footer.Link>Privacy Policy</Footer.Link>
                </Link>
                <Link to={"/cancel-refund-polycy"}>C & Refund Policy</Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="#" by="Asha bhel" year={1963} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon
              href="https://www.facebook.com/AshaBhelOfficial/"
              icon={BsFacebook}
            />
            <Footer.Icon
              href="https://www.instagram.com/ashabhel1963/"
              icon={BsInstagram}
            />
            <Footer.Icon href="#" icon={BsTwitter} />
            <Footer.Icon href="https://wa.me/918600222200" icon={BsWhatsapp} />
          </div>
        </div>
      </div>
    </Footer>
  );
}

export default FooterPage;
